@import "~sharedFromCoreComponents";

.wrapper {
  padding: 25px 0;

  @media (max-width: $screen-sm-max) {
    padding: 30px 0 0 0;
  }
}

.title {
  font-size: 2em;
  text-align: center;
  margin: 0px;
}
