@import "~sharedFromCoreComponents";

.one {
  grid-column: span 1;
}
.two {
  grid-column: span 2;
}
.three {
  grid-column: span 3;
}
.wrapper {
  margin: 15px;
  position: relative;
  .image {
    width: 100%;
    height: 225px;
  }
  &_rounded {
    overflow: hidden;
    .image {
      border-radius: 100%;
      width: 180px;
      height: 180px;
    } 
  }

  @media (max-width: $screen-sm-max) {
    margin: 10px;
    .image {
      width: 100%;
      object-fit: cover;
    }
    &_rounded {
      margin: 10px;
      overflow: hidden;
      .image {
        border-radius: 100%;
        width: 140px;
        height: 140px;
        margin: 8px 20px 8px 20px;
      } 
    }
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 auto;
    padding-top:22px;
    &_image{
      width: 100%;
      max-width: 270px;
    }
    .image {
      width: 100%;
      max-width: 270px;
      object-fit: cover;
    }
    &_rounded {
      overflow: hidden;
      padding-top: 0px;
      .image {
        border-radius: 100%;
        width: 120px;
        height: 120px;
        margin: 8px 25px 8px 25px;
      } 
    }
  }
}
.title {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  line-height: 50px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: rgba(0,0,0,.6);
  text-transform: uppercase;

  &_in_rounded_wrapper{
    text-align: center;
    font-size: 16px;
    text-transform: none;
    position: relative;
    background-color: white;
    color: #333333;
    font-weight: 400;
    width: 180px;
    line-height: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    @media (max-width: 765px) {
      width: 170px;
    }
  }

  &_in_contentWrapper{
    line-height: 30px;
    padding: 2px 14px; /* adjust padding as needed */  
    word-wrap: break-word;

    @media (max-width: $screen-sm) {
      padding: 2px 10px;
      line-height: 25px;
      font-size: 16px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 4px 14px;
      line-height: 22px;
      font-size: 16px;
    }
  }
}
