@import "~sharedFromCoreComponents";

.wrapper {
  padding: 25px 0;
  background-color: #881155;
}

.title {
  font-size: 2em;
  text-align: center;
  color: #FFF;
  margin: 25px 0;
}

.links {
  @media (max-width: $screen-sm-max) {
    width: 100vw;
  }

  width: 960px;
  margin: 0 auto;
  column-count: 5;
  padding-left: 40px;

  @media (max-width: $screen-sm-max) {
    column-count: 1;
    text-align: center;
    padding: 0;
  }

  .link {
    padding-bottom: 6px;
    line-height: 0.7em;

    @media (max-width: $screen-sm-max) {
      padding-bottom: 2px;
    }

    a {
      color: #FFF;
      font-size: 10px;
    }
  }
}

.footer {
  text-align: center;

  a {
    color: #FFF;
    font-size: 12px;
  }
}
