$minW: 1440px;
$h: 750px;
$step: calc(100% - #{$minW} + 120px);
$breakpoint: 840px;
$xsBreakpoint: 385px;

@mixin height {
    @media (min-width: $breakpoint) {
        height: 750px;
    }
    @media (max-width: calc(#{$breakpoint} - 1px)) {
        height: 610px;
    }
}

@mixin web {
    @media (min-width: $breakpoint) {
        @content;
    }
}

@mixin mweb {
    @media (max-width: calc(#{$breakpoint} - 1px)) {
        @content;
    }
}

.wrapper {
    @include height;
    background: black;
    position: relative;
    max-width: 100vw;
}

.splash {
    position: absolute;
    width: fit-content;
    text-align: center;

    img {
        @include height;
        object-fit: cover;
    }
}

.gradientWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    @include height;
}

.heroGradient {
    background: linear-gradient(to right, #000000 0, #00000000 $step, #00000000 calc(100% - #{$step}), #000000 100%);
    width: 100%;
    @include height;
    margin: auto;
}

.main {
    flex: 1;
    position: absolute;
    top: 494px;
    left: 144px;

    @include mweb {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        left: 0;
        bottom: 112px;
        width: 100%;
    }
}

.title {
    font-size: 30px;
    color: #ffffff;
    text-shadow: 0 4px 14px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;

    @include mweb {
        font-size: 24px;
    }
}

.footer {
    --footer-height: 94px;
    padding: 16px 48px;
    width: calc(100% - 48px);
    max-width: 100%;
    height: var(--footer-height);
    position: relative;
    left: 0;
    right: 0;
    bottom: var(--footer-height);
    margin: 0 auto;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    border-radius: 10px 10px 0 0;
    color: #1a2026;

    display: flex;
    flex-direction: row;
    align-items: center;

    @include web {
        white-space: nowrap;
        line-height: 24px;
    }

    @include mweb {
        padding: 16px 152px 16px 16px;
        margin-top: 8px;
        width: calc(100% - 32px);
        height: 88px;
    }
}

.venueName {
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    color: #1a2026;

    @include web {
        padding-right: 32px;
    }

    @include mweb {
        font-size: 16px;
        white-space: break-spaces;
        text-overflow: ellipsis;
    }
}

.venueLocation {
    font-weight: 600;
    font-size: 14px;
    color: #1a2026;

    @include web {
        padding-right: 32px;
    }
}

.venueDescription {
    font-size: 13px;
    padding-right: 32px;
    padding-bottom:5px;
    white-space: normal;
    flex: 1;
    line-height: 20px;

    @include mweb {
        display: none;
    }

    @media (max-width: $minW) {
        line-height: 20px;
    }
}

.venueLink {
    font-size: 14px;

    a {
        color: #881155;
        display: flex;
        align-items: center;

        span {
            margin: 0 8px;
        }
    }

    button {
        border-radius: 23px;
        padding:10px;
    }

    @include mweb {
        white-space: nowrap;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.venueInfo {
    @include web {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    }

    @include mweb {
        display: block;
    }
}

.venueTitle {
    display: flex;
    padding-top:8px;

    @media (max-width: $minW) {
        padding-top:8px;
    }

    @include mweb {
        display: block;
    }
}

.icon {
    padding: 0 32px 3px 0;
    font-size: 16px;
    line-height: 125%;

    color: #1a2026;

    @include mweb {
        font-size: 14px;
    }
}

.gallery {
    @include height;

    & > div {
        @include height;

        & > div {
            @include height;
        }
    }

    :global {
        .image-gallery-bullets {
            bottom: 108px;
        }
    }
}

.slide {
    @include height;
    position: relative;

    .blur {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        filter: blur(7px);
        background-size: cover;
        background-position:center center;
        z-index: -1;
    }
    img {
        position: relative;
        z-index: 1;
        object-fit: contain;
        width: 100%;
        height: 100%;
        filter: blur(-4px);

        @media (max-width: 1500px) {
            object-fit: cover;
        }
    }

    &.ballroom {
        &:before,
        &:after {
            display: none;
        }
        img {
            @media (max-width: 2250px) {
                object-fit: cover;
            }
        }
    }
}

.input {
    display: flex;
    width: 564px;
    height: 68px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    border-radius: 60px;
    padding: 12px 12px 12px 24px;

    input {
        height: 100%;
        width: 100%;
        padding: 0;
        border: none;
        box-shadow: none;

        @media (max-width: $xsBreakpoint) {
            font-size: 11px;
        }
    }

    & > div {
        flex: 1;
    }

    button {
        border-radius: 60px;
        padding: 5px;
        height:auto;
        @media (max-width: $xsBreakpoint) {
            font-size: 11px;
            min-width: 56px;
        }
    }

    @include mweb {
        width: 100%;
        height: 56px;
        padding: 6px 6px 6px 15px;
    }
}
