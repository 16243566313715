@import "~sharedFromCoreComponents";

.wrapper {
  text-align: center;
  padding: 50px 0 40px 0;

  @media (max-width: $screen-sm-max) {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
  }
}

.image {
  position: absolute;
  @media (max-width: $screen-sm-max) {
    position: relative;
  }

  img {
    height: 100px;
  }
}

.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #a43b66;
  margin-top: 0px;
}

.copy {
  max-width: 620px;
  margin: auto;
  font-size: 16px;
  font-weight: 400;
  color: #47505e;
}

.link {
  padding-top: 30px;

  a {
    font-size: 16px;
    font-weight: 600;
    color: #962151;
  }
}

.divider {
  border-bottom: 2px solid #d8d8d8;
  position: absolute;
  left: 0;
  width: 100%;
  padding-top: 40px;

  @media (max-width: $screen-sm-max) {
    position: relative;
  }
}
