.wrapper {
  display: flex;
  text-align: center;
}

.box {
  padding: 12px 30px;

  .title {
    margin: 20px 0;
    color: #333;
    font-size: 18px;
  }

  .copy {
    font-size: 15px;
    margin-bottom: 10px;
  }

  a {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #815;
  }
}

.iconPricetag, .iconSave, .iconSearch {
  background-image: url('../../../../assets/images/home-sprite.png');
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  display: inline-block;
}

.iconPricetag {
  background-position: -80px -250px;
}

.iconSave {
  background-position: -106px -131px;
}

.iconSearch {
  background-position: 0 -250px;
}
