@import "~sharedFromCoreComponents";

.wrapper {
  display: grid;
  margin: 0 30px;
  &_rounded {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: $screen-sm-max) {
    margin: 15px 0;
  }
}

.footer {
  font-size: 18px;
  text-align: center;
}
#carouselContainer {
  max-width: 900px;
  margin: auto;
  div > div {
    padding: 0px;
    button {
      opacity: 1;
    }
    div > ul > li {
      padding: 0px;
      @media (max-width: $screen-xs-max) {
        padding: 8px;
      }
    }
  }
}