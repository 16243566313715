@import "~sharedFromCoreComponents";

.wrapper {
    font-size: 22px;

    @media (max-width: $screen-sm-max) {
        font-size: 16px;
    }
}

.inner {
    margin: 0 auto;
    width: 960px;


    @media (max-width: $screen-sm-max) {
        width: 80%;
        margin: 0 auto;
    }
    @media (max-width: $screen-xs) {
        width: calc(100% - 10px);
        margin: 0 5px;
    }
}
